<style lang="less" scoped>
/deep/ .ant-form-item-label {
  width: 75px !important;
  text-align: left !important;
}
/deep/ .ant-table-tbody tr {
  background-color: #fff;
}
.table-page-search-submitButtons .ant-btn-primary{
  margin-left: 24px;
}
</style>
<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="12">
            <a-form-item label="订单号">
              <a-input v-model="queryParam.orderSn" placeholder="" />
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="12">
            <a-form-item label="手机号">
              <a-input v-model="queryParam.customerPhoneNumber" placeholder="" />
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="12">
            <a-form-item label="类型">
              <a-select placeholder="全部" v-model="queryParam.paymentWay">
                <a-select-option :key="item.enumValue" v-for="item in paymentWayData" :value="item.enumValue">
                  {{ item.enumName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="48">
          <a-col :md="6" :sm="12">
            <a-form-item label="提货方式">
              <a-select placeholder="全部" v-model="queryParam.pickupType">
                <a-select-option :key="item.enumValue" v-for="item in pickupTypeData" :value="item.enumValue">
                  {{ item.enumName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
              <a-col :md="6" :sm="12">
                  <a-form-item label="派货方式">
                  <a-select placeholder="全部"  v-model="queryParam.dispatchType">
                        <a-select-option :key="item.enumValue" v-for="item in dispatchTypeData" :value="item.enumValue">
                          {{ item.enumName }}
                        </a-select-option>
                  </a-select>
                  </a-form-item>
              </a-col>

              <a-col>
                  <span class="table-page-search-submitButtons">
                    <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                    <a-button style="margin-left: 8px" @click="() => queryParam = {}">重置</a-button>
                  </span>
              </a-col>
          </a-row>
        </a-row>
      </a-form>
    </div>

    <s-table
      ref="table"
      size="default"
      rowKey="orderSn"
      :columns="columns"
      :data="loadData"
      :alert="options.alert"
      :page-size="10"
      :scroll="{ x: 'max-content' }"
    >
      <span slot="luxian" slot-scope="text, record">
        <template>
          <span>{{ record.shipperGeoInfo.cityName }} 至 {{ record.receiverGeoInfo.cityName }}</span>
        </template>
      </span>

      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleDetail(record)" v-action:orderDetailButton>详情</a>
          <a @click="takingOrderConfirm(record)" v-action:takingOrder>&ensp;|&ensp;接单</a>
        </template>
      </span>
    </s-table>

    <a-modal
      title="接单"
      :width="200"
      :visible="takeOrderVisible"    
      @cancel="takeOrderCancel"
    >
      <a-row :gutter="24">
          <a-col :span="24">            
              是否接单？
          </a-col>
          
        </a-row>   
    <template slot="footer">
      <a-button key="cancel"  @click="takeOrderCancel"  style="margin-right:10px">否</a-button>
      <a-button  key="forward"  type="primary" @click="takingOrder">是</a-button>      
    </template>
  </a-modal>  

  </div>
</template>

<script>
import { STable } from '@/components'
import { getOrderTakingList, orderTaking } from '@/api/order'

export default {
  name: 'TableList',
  components: {
    STable,
  },
  data() {
    return {
      searchTypeData: [],
      cancelAuditStateData: [],
      paymentWayData: [],
      pickupTypeData: [],
      dispatchTypeData: [],
      takeOrderVisible:false,
      orderId: 0,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '订单号',
          align: 'center',
          dataIndex: 'orderSn',
          width: '200px',
          fixed: 'left',
        },
        {
          title: '下单时间',
          align: 'center',
          dataIndex: 'createDate',
          width: '20%'
        },
        {
          title: '路线',
          align: 'center',
          dataIndex: 'luxian',
          width: '20%',
          scopedSlots: { customRender: 'luxian' },
        },
        {
          title: '提货',
          align: 'center',
          dataIndex: 'pickupTypeVal',
          width: '10%'
        },
        {
          title: '派货',
          align: 'center',
          dataIndex: 'dispatchTypeVal',
          width: '10%'
        },
        {
          title: '支付类型',
          dataIndex: 'paymentWayVal',
          align: 'center',
          width: '10%'
        },
        {
         title: ''
        },
        {
          title: '操作',
          align: 'center',
          dataIndex: 'action',
          width: '200px',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],

      // 加载数据方法 必须为 Promise 对象
      loadData: (parameter) => {
        return getOrderTakingList(Object.assign(parameter, this.queryParam)).then((res) => {
          if(res.code == 0){

             this.searchTypeData = res.result.searchTypeData
             this.cancelAuditStateData = res.result.cancelAuditStateData
             this.paymentWayData = res.result.paymentWayData
             this.pickupTypeData = res.result.pickupTypeData
             this.dispatchTypeData = res.result.dispatchTypeData
             return res.result
          }else{
             this.$notification.error({
              message: '加载失败',
              description: res.message,
            })
          }

        })
      },

      options: {

      },

    }
  },
  filters: {

  },
  created() {

  },
  methods: {

   takingOrderConfirm(record){
      this.orderId = record.id
      this.takeOrderVisible = true
   },   
   takeOrderCancel(){
        this.takeOrderVisible = false
   },

    takingOrder() {
      let _this = this
      var param = { orderId: this.orderId }
      return orderTaking(param).then((res) => {
        this.takeOrderVisible = false
        if (res.code == 0) {
          _this.$notification.success({
            message: '接单',
            description: `接单成功`,
          })
          _this.$refs.table.refresh(true)
        } else {
          _this.$notification.error({
            message: '接单',
            description: res.message,
          })
        }
      })
    },

   
    handleDetail(record) {
      this.$router.push({ name: 'orderDetail', query: { orderId: record.id } })
    },
  },
}
</script>
